import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody} from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';


import loadable from '@loadable/component';
import UsageLineChart from './UsageLineChart';
import { accountService } from '../../services';
import { AuthContext } from '../../context/Auth';
import Progress from 'reactstrap/es/Progress';
import Flex from '../common/Flex';


const Dashboard = () => {
  // State
  const { currentAccount } = useContext(AuthContext);
  const [currCycleUsage, setCurrCycleUsage] = useState({
    totalCount: 0,
    successCount: 0,
    errorCount: 0
  });

  console.log(currentAccount)
  const activeSubscription = currentAccount.subscriptions.find(sub => sub.isActive);
  const activeCycle = activeSubscription.cycles.find(cycle => cycle.isActive);
  const activePlan = activeSubscription.plan;
  const per = (currCycleUsage.successCount / activePlan.apiCallsPerMonth) * 100;
  console.log(per);
  const price = activePlan.price;
  console.log(price);

  const getCurrentCycleUsage = async () => {
    try {
      const res = await accountService.get('/accounts/current-cycle');
      setCurrCycleUsage(res.data);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getCurrentCycleUsage();
  }, []);

  return (
    <Fragment>
      {/*<PaymentsLineChart />*/}
    {/*   <div className="card-deck">
        <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
          <CardBody>
            <Row>
              <Col>
                {activePlan.name}
              </Col>
            </Row>
            <Flex align="center">
              <Progress
                animated={true}
                value={per}
                color="primary"
                className="w-100 mr-2 rounded-soft bg-200"
                // barClassName="rounded-capsule"
                style={{ height: '100%' }}
              />
              <div className="font-weight-semi-bold ml-2">{currCycleUsage.successCount}/{activePlan.apiCallsPerMonth}</div>
            </Flex>
            <Row>
              <Col>
                <Row><Col>from: {new Date(activeCycle.startDate).toLocaleDateString()}</Col></Row>
                <Row><Col>to:   {new Date(activeCycle.endDate).toLocaleDateString()}</Col></Row>

              </Col>
              <Col>
                {price.toLocaleString('en-IN', {style: "currency", currency: "USD"})}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <CardSummary title="Success Calls" color="success">
          <CountUp end={currCycleUsage.successCount} duration={2} prefix="" separator="," decimal="." />
        </CardSummary>
        <CardSummary title="Error Calls" color="danger">
          <CountUp end={currCycleUsage.errorCount} duration={2} prefix="" separator="," decimal="." />
        </CardSummary>
      </div> */}
      <UsageLineChart/>

    </Fragment>
  );
};

export default Dashboard;
