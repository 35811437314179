export default
    [
        {
            name:"Java",
            warm_engine_flutter_code_lines:`FlutterEngine flutterEngine = new FlutterEngine(this);
flutterEngine.getDartExecutor().executeDartEntrypoint(DartExecutor.DartEntrypoint.createDefault());
FlutterEngineCache.getInstance().put("my_engine_id", flutterEngine);`,
            run_sdk_code_lines:`String NEEDED_DATA_SDK= "{"api_key":"API-KEY","personId":"0000000000"}";
final Intent my_engine_id = FlutterActivity.withCachedEngine("my_engine_id").build(this);
BinaryMessenger bM =flutterEngine.getDartExecutor().getBinaryMessenger();
new MethodChannel(bM,"Altscore/SDK").invokeMethod("SEND_NEEDED_DATA",NEEDED_DATA_SDK);
startActivity(my_engine_id);`
        },
        {
            name:"Kotlin",
            warm_engine_flutter_code_lines:`val flutterEngine = FlutterEngine(this)
flutterEngine.dartExecutor.executeDartEntrypoint(DartExecutor.DartEntrypoint.createDefault())
FlutterEngineCache.getInstance().put("my_engine_id", flutterEngine)`,
            run_sdk_code_lines:`val sdkData:String = "{\"api_key\":\"API-KEY\",\"personId\":\"0000000000\"}"
val myEngine:Intent = io.flutter.embedding.android.FlutterActivity.withCachedEngine("my_engine_id").build(this);
val bM:BinaryMessenger=flutterEngine.getDartExecutor().getBinaryMessenger()
MethodChannel(bM,"Altscore/SDK").invokeMethod("SEND_NEEDED_DATA",sdkData)
startActivity(myEngine);`
        },
    ]