import React, { useState, useContext, useEffect } from 'react';
import AppContext from '../../context/Context';
import { Row, Col, Card, CardBody, CustomInput, Table } from 'reactstrap';
import FormGroupSelect from '../common/FormGroupSelect';
const TableApiCalls = props => {
  const { isDark } = useContext(AppContext);
  const { listByDateCalls } = props;
  const INITIAL_STATE_DATES = ['Select a Date'];
  const [listDates, setListDates] = useState(INITIAL_STATE_DATES);
  const [listApiCalls, setListApiCalls] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  const getDates = () => {
    if (listByDateCalls.length == 0) {
      setListDates(INITIAL_STATE_DATES);
      setListApiCalls([]);
    } else {
      console.log(listByDateCalls);
      listByDateCalls.map(el => {
        let list = INITIAL_STATE_DATES;
        list.push(el.date);
        setListDates(list);
      });
    }
  };
  const getListApiCalls = () => {
    const filterList = listByDateCalls.filter(el => el.date == selectedDate);
    if (filterList.length > 0) {
      setListApiCalls([...filterList[0].apiCalls]);
    } else {
      setListApiCalls([]);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    setListDates(INITIAL_STATE_DATES);
    getDates();
  }, [listByDateCalls]);

  useEffect(() => {
    getListApiCalls();
  }, [selectedDate]);

  const tableMobile = () => {
    return (
      <Table>
        <tbody>
          {listApiCalls.length > 0 ? (
            listApiCalls.map((list, index) => {
              return (
                <tr key={index + 1}>
                  <Row>
                    <Col>
                      <strong className="text-white mb-0">N°: </strong> {index + 1}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <strong className="text-white mb-0">Fecha: </strong>
                      {list.date}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <strong className="text-white mb-0">ApiCallId: </strong>
                      {list.apiCallId}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <strong className="text-white mb-0">StatusCode:</strong> {list.statusCode}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <strong className="text-white mb-0">Success: </strong>
                      {list.isSuccess.toString()}
                    </Col>
                  </Row>
                  {index < listApiCalls.length - 1 ? (
                    <hr
                      style={{
                        color: 'red',
                        height: 5
                      }}
                    />
                  ) : null}
                </tr>
              );
            })
          ) : (
            <Row className="d-flex justify-content-center">
              <Col className="text-center">
                <p>Data not found</p>
              </Col>
            </Row>
          )}
        </tbody>
      </Table>
    );
  };
  const tableDesktop = () => {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>N°</th>
            <th>Fecha</th>
            <th>ApiCallId</th>
            <th>Status Code</th>
            <th>Sucess</th>
          </tr>
        </thead>
        <tbody>
          {listApiCalls.length > 0 ? (
            listApiCalls.map((list, index) => {
              return (
                <tr key={index + 1}>
                  <th scope="row">{index + 1}</th>
                  <td>{list.date}</td>
                  <td>{list.apiCallId}</td>
                  <td>{list.statusCode}</td>
                  <td>{list.isSuccess!=null?list.isSuccess.toString():""}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th colspan="5">
                <Row className="d-flex justify-content-center">
                  <Col className="text-center">
                    <p>Data not found</p>
                  </Col>
                </Row>
              </th>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };
  return (
    <div>
      <Card className="mb-3">
        <CardBody className="rounded-soft bg-gradient">
          <Row className="text-white align-items-center no-gutters">
            <FormGroupSelect
              loading={false}
              type={'select'}
              id="date"
              label="Dates"
              options={listDates.map(dates => {
                return { value: dates, label: dates };
              })}
              value={selectedDate}
              onChange={({ target }) => setSelectedDate(target.value)}
            />
          </Row>
          {width < breakpoint ? tableMobile() : tableDesktop()}
        </CardBody>
      </Card>
    </div>
  );
};
export default TableApiCalls;
