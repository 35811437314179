import React, { useEffect, Fragment, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import Col from 'reactstrap/es/Col';
import CustomInput from 'reactstrap/es/CustomInput';
import Media from 'reactstrap/es/Media';
import Row from 'reactstrap/es/Row';
import Alert from 'reactstrap/lib/Alert';
import CodeHighlight from '../components/common/CodeHighlight';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Flex from '../components/common/Flex';
import PageHeader from './../components/common/PageHeader';
import DataDictionaryComponent from './DataDictionaryComponent';
import programmingLanguagesCode from './data/implementation_code_data';
import { isIterableArray } from '../helpers/utils';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import rawDictionary from './data/dictionary_data.json';
import pdfDocumentEcuador from './data/altscore_response_ec.pdf';
import pdfDocumentPeru from './data/altscore_response_peru.pdf';
import { accountService } from '../../src/services';
import { toast } from 'react-toastify';
import ButtonIcon from '../components/common/ButtonIcon';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
    backgroundColor: '#0e1d2f'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    color: '#d8e3ef'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    color: '#d8e3ef'
  },
  posible_values_text: {
    marginBottom: 7,
    fontSize: 12,
    textAlign: 'left',
    color: '#d8e3ef'
  },
  noDescrioption: {
    fontFamily: 'font-italic ',
    color: '#d8e3ef'
  },
  subtitle: {
    fontSize: 14,
    color: '#2c7be5',
    marginBottom: 7
  },
  parent: {
    fontSize: 16,
    color: '#2c7be5',
    marginBottom: 5
  },
  text: {
    marginTop: 7,
    marginLeft: 10,
    fontSize: 10,
    color: '#8edcfd'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#8edcfd'
  },
  section: {
    marginLeft: 10,
    flexGrow: 1
  },
  titleSection: {
    flexGrow: 2,
    alignContent: 'flex-start',
    flexDirection: 'column'
  },
  container: {
    paddingVertical: 10
  },
  child: {
    marginTop: 5,
    fontSize: 12,
    marginLeft: 10,
    color: '#d8e2ef'
  },
  posibleValuesTable: {
    flexDirection: 'row',
    flexGrow: 2,
    border: '1',
    borderColor: '#d8e3ef'
  },
  type: {
    marginLeft: 10,
    color: '#5ce2aa',
    fontSize: 16
  },
  table: {
    borderColor: '#d8e3ef',
    border: '1'
  },
  table_details: {
    border: '1',
    borderColor: '#d8e3ef'
  },
  row: {
    minWidth: 100
  },
  table_header: {
    margin: 10,
    fontSize: 12,
    color: '#2c7be5'
  },
  container_table: {
    margin: 10
  },
  text_row: {
    marginVertical: 10,
    marginLeft: 10,
    fontSize: 10,
    color: '#8edcfd'
  },
  titleTable: {
    flexDirection: 'row',
    flexGrow: 2
  }
});

const IndexContainer = ({ indexData }) => {
  return (
    <View style={styles.container}>
      {indexData.childs != null && isIterableArray(indexData.childs) ? (
        <View>
          <Text style={styles.parent}>{indexData.indexName}</Text>
        </View>
      ) : (
        <View>
          <View style={styles.titleTable}>
            <Text style={styles.parent}>{indexData.indexName}</Text>
            <Text style={styles.type}>{indexData.type != '' ? indexData.type : 'unknown'}</Text>
          </View>
          <Text style={styles.posible_values_text}>
            {indexData.description != '' ? indexData.description : 'Sin Descripcion'}
          </Text>
          {indexData.error_values != null && isIterableArray(indexData.error_values) ? (
            <View style={styles.container_table}>
              <Text style={styles.posible_values_text}>Errores posibles</Text>
              <View style={styles.table_details}>
                <View style={styles.posibleValuesTable}>
                  <View style={styles.row}>
                    <Text style={styles.table_header}>Valor</Text>
                  </View>

                  <Text style={styles.table_header}>Descripción</Text>
                </View>
                {indexData.error_values.map((error_value, index) => {
                  return (
                    <View style={styles.posibleValuesTable}>
                      <View style={styles.row}>
                        <Text style={styles.text_row}> {error_value.value}</Text>
                      </View>

                      <Text style={styles.text_row}>{error_value.description}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          ) : (
            <View />
          )}
        </View>
      )}
      <View style={styles.section}>
        {indexData.childs != null && isIterableArray(indexData.childs) ? (
          indexData.childs.map((child, index) => <IndexContainer indexData={child} />)
        ) : (
          <View />
        )}
      </View>
    </View>
  );
};

const MyDocument = () => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Altscore Data Dictionary</Text>
        <Text style={styles.author}>kinlending</Text>
        {isIterableArray(rawDictionary) &&
          rawDictionary.map((word, index) => {
            return <IndexContainer indexData={word} />;
          })}
        <Text fixed
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const APIReference = () => {
  const [programmingLanguageIndex, setProgrammingLanguageIndex] = useState(0);
  const [country, setCoutry] = useState(0);

  function copyCodeToClipboard() {
    const el = document.createElement('textarea');
    el.value = "https://api.altscore.ai/v1/test";
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy")
    toast.success(`url copied to clipboard`);
  }

  function copyLinkToClipboard() {
    const el = document.createElement('textarea');

    el.value = window.location.href == 'http://localhost:3000/docs' ?
      'http://localhost:4000/swagger-export' : window.location.href == 'https://staging.dashboard.altscore.ai/docs' ?
        'https://staging.api.altscore.ai/swagger-export' :
        'https://api.altscore.ai/swagger-export';
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy")
    toast.success(`url copied to clipboard`);
  }

  return (
    <Fragment>
      <div>
        <PageHeader title="Referencia de API" className="mb-3">
          <p className="mt-2 mb-0">
            Nuestra <code>REST API</code> tiene URLs predecibles orientadas a recursos, acepta solicitudes con formato de codificación `form-encoded` y devuelve respuestas{' '}
            <code> codificadas en JSON </code> utilizando códigos de respuesta, autenticación y verbos{' '}
            <code> HTTPS </code> estándar.
          </p>
        </PageHeader>

        <Card>
          {/*     <PDFViewer>
                    <MyDocument />
                </PDFViewer> */}
          <FalconCardHeader title="Autenticación" />
          <Row>
            <Col xs="12">
              <CardBody>
                <p>
                  La API de <code>Altscore </code> utiliza <code>API Keys</code> para autenticar solicitudes. Puede ver
                  y administrar sus <code>API Keys </code> en el panel de Altscore.
                </p>
              </CardBody>
              <Card>
                <CardBody>
                  <Alert color="info" className="px-0 pt-0 bg-white">
                    <FalconCardHeader className="ml-0 mt-0" title="Solicitud autenticada">
                      <Flex>
                        <CustomInput
                          type="select"
                          id="exampleCustomSelect"
                          bsSize="sm"
                          className="select-month mr-2"
                          value={programmingLanguageIndex}
                          onChange={({ target }) => setProgrammingLanguageIndex(target.value)}
                        >
                          {isIterableArray(programmingLanguagesCode) &&
                            programmingLanguagesCode.map((language, index) => (
                              <option key={index} value={index}>
                                {language.name}
                              </option>
                            ))}
                        </CustomInput>
                      </Flex>
                    </FalconCardHeader>
                    <Media>
                      <Media body className="ml-3">
                        <p>
                          Para realizar una llamada a la <code>API de Altscore</code>, debe tener una{' '}
                          <code>API Key</code> e incluirla en el encabezado de la solicitud <code>HTTPS</code>.
                        </p>
                        <h6 className="mt-3">
                          Si usted usa{' '}
                          <strong>
                            <code>{programmingLanguagesCode[programmingLanguageIndex].name}</code>
                          </strong>{' '}
                          , ejecute:
                        </h6>
                        {programmingLanguagesCode[programmingLanguageIndex] != null ? (
                          <CodeHighlight
                            code={programmingLanguagesCode[programmingLanguageIndex].autentication_code_text}
                            language="bash"
                          />
                        ) : (
                          <div />
                        )}
                      </Media>
                    </Media>
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Card>

        <Card className="mt-3">
          <FalconCardHeader title="End Point Único" />
          <Row>
            <Col xs="12">
              <CardBody>
                <p>
                  El punto final de la API de Altscore es único y fácil de usar. solo necesitas conocer los parámetros
                  que se deben enviar en la solicitud HTTPS
                </p>
                <h6 className="mt-3">
                  La{' '}
                  <strong>
                    <code>base url</code>
                  </strong>{' '}
                  es:
                </h6>
                <CodeHighlight code={`POST 'https://api.altscore.ai/v1/'`} language="bash" />
                <Alert color="info" className="p-4 mb-0 bg-white">
                  <h4 className="alert-heading">Parametros</h4>
                  <ul>
                    <li>
                      <code>countryCode</code> representa el código del país que desea consultar
                      <ul>
                        <li>
                          Ejemplo: <code>"countryCode"</code>:<code>"ec"</code>
                        </li>
                        <li>
                          valores aceptados:<code>"ec"</code>,<code>"pe"</code>,<code>"co"</code>
                        </li>
                      </ul>
                    </li>
                    <br />
                    <li>
                      <code>personId</code> representa el documento de identificación de la persona a consultar
                      <ul>
                        <li>
                          Ejemplo: <code> personId=0000000000</code>
                        </li>
                      </ul>
                    </li>
                    <br />
                    <br />
               {/*      <li>
                      <code>vehiclesIds (Opcional)</code> representa las placas de los vehículos de la persona para tener
                      información adicional de los bienes de dicha persona
                      <ul>
                        <li>
                          Ejemplo: <code> vehiclesIds=PKF8330,PGT3577</code>
                        </li>
                      </ul>
                    </li>
                    <br /> */}
                    <li>
                      <code>location (Opcional)</code> representa un objeto con las siguientes propiedades
                    </li>
                    <ul>

                      <li>
                        <code>locationType</code> determina el tipo de ubicación donde se encuentra
                      <ul>
                          <li>
                            Ejemplo: <code> "locationType"</code>:<code> "HOME"</code>
                          </li>
                          <li>
                            valores aceptados:<code> "HOME"</code>,<code>"WORK"</code>,<code>"ANY"</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>lat</code> representa la latitud donde se encuentra la persona
                      <ul>
                          <li>
                            Ejemplo: <code> "lat"</code>:<code>0.2034604</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>lng</code> representa la longitud donde se encuentra la persona
                      <ul>
                          <li>
                            Ejemplo: <code> "lng"</code>:<code>-78.445517</code>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <br></br>
                    <li>
                      <code>address (Opcional)</code> representa un objeto con las siguientes propiedades
                    </li>
                    <ul>

                      <li>
                        <code>type</code> determina el tipo de ubicación donde se encuentra
                      <ul>
                          <li>
                            Ejemplo:  <code>"type"</code>: <code>"HOME"</code>
                          </li>
                          <li>
                            valores aceptados:<code> "HOME"</code>,<code>"WORK"</code>,<code>"ANY"</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>country</code> representa el código de pais donde se localiza la persona
                      <ul>
                          <li>
                            Ejemplo: <code>"country"</code>:<code>"ec"</code>
                          </li>
                          <li>
                            valores aceptados:<code>"ec"</code>,<code>"pe"</code>,<code>"co"</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>city</code> representa el nombre de la ciudad donde se localiza la persona
                      <ul>
                          <li>
                            Ejemplo: <code>"city"</code>:<code>"Quito"</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>street1</code> representa el nombre de la calle principal
                      <ul>
                          <li>
                            Ejemplo: <code>"street1"</code>:<code> "Av. 6 de diciembre"</code>
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <code>street2</code> representa el nombre de la calle secundaria
                      <ul>
                          <li>
                            Ejemplo: <code>"street2"</code>:<code>"Av. Portugal"</code>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                </Alert>

                <Alert color="info" className="p-4 mb-0 bg-white">
                  <h4 className="alert-heading">Errores</h4>
                  <p>
                    <code>Altscore API</code> utiliza códigos de respuesta HTTP convencionales para indicar el éxito o el fracaso de una solicitud de API. En general: los códigos en el rango indican éxito. Los códigos en el rango indican un error que falló dada la información proporcionada (por ejemplo, se omitió un parámetro requerido). Los códigos en el rango indican un error con los servidores de Altscore 2xx 4xx 5xx
                  </p>
                  <Card>
                    <FalconCardHeader title="Resumen codigos http" />
                    <Table>
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Campo relacionado</th>
                          <th>Posibles mensajes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            400
                            </td>
                          <td>
                            countryCode
                            </td>
                          <td>
                            countryCode is required
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            personId
                            </td>
                          <td>
                            personId is required
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            locationType
                            </td>
                          <td>
                            locationType missing
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            lat
                            </td>
                          <td>
                            lat missing
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            lng
                            </td>
                          <td>
                            lng missing
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            email
                            </td>
                          <td>
                            Invalid format of email address
                            </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            vehiclesIds
                            </td>
                          <td>
                            The plate AAA-0000 is invalid
                            </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Alert>
              </CardBody>
            </Col>
            <Col xs="12" >
              <Card className="m-3">
                <Alert color="info" className="px-0 pt-0 bg-white">
                  <FalconCardHeader className="ml-0 mt-0" title="Implementación">
                    <Flex>
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        bsSize="sm"
                        className="select-month mr-2"
                        value={programmingLanguageIndex}
                        onChange={({ target }) => setProgrammingLanguageIndex(target.value)}
                      >
                        {isIterableArray(programmingLanguagesCode) &&
                          programmingLanguagesCode.map((language, index) => (
                            <option key={index} value={index}>
                              {language.name}
                            </option>
                          ))}
                      </CustomInput>
                    </Flex>
                  </FalconCardHeader>
                  <CardBody>
                    <Media>
                      <Media body className="ml-3">
                        <p className="mb-0">
                          Para llamar al <code>Altscore endpoint</code> se necesita tener un <code>API Key</code>
                        </p>
                        <p>
                          {' '}
                          incluir en el encabezado de la solicitud <code>HTTPS</code>, e incluir los{' '}
                          <code>parametros</code> en la <code>URL</code>
                        </p>
                        <h6 className="mt-3">
                          Si usted usa{' '}
                          <strong>
                            <code>{programmingLanguagesCode[programmingLanguageIndex].name}</code>
                          </strong>{' '}
                          , ejecute:
                        </h6>
                        <div />
                        {programmingLanguagesCode[programmingLanguageIndex] != null ? (
                          <CodeHighlight
                            code={programmingLanguagesCode[programmingLanguageIndex].implementation_code_text}
                            language="bash"
                          />
                        ) : (
                          <div />
                        )}
                      </Media>
                    </Media>
                  </CardBody>
                </Alert>
              </Card>
            </Col>
          </Row>
        </Card>

        <Card className="mt-3 mb-3">
          {/*                                     <PDFDownloadLink document={<MyDocument />} fileName="altscore_response.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    </PDFDownloadLink> */}
          {/*    <PDFViewer>
            <MyDocument />
          </PDFViewer> */}

          <FalconCardHeader title="Respuesta" >
            {/*                         <div>
              <CustomInput
                type="select"
                id="exampleCustomSelect"
                bsSize="sm"
                className="select-month mr-2"
                value={country}
                onChange={({ target }) => setCoutry(target.value)}
              >
                <option key={0} value={0}>
                  Ecuador
                            </option>
                <option key={1} value={1}>
                  Peru
                            </option>
              </CustomInput>
            </div>
            {
              country == 0 ?
                (<a href={pdfDocumentEcuador} download="altscore_response.pdf">Descargar como PDF</a>) : (<a href={pdfDocumentPeru} download="altscore_response_ec.pdf">Descargar como PDF</a>)
            } */}
            <a href={pdfDocumentEcuador} download="altscore_response_ec.pdf">Descargar como PDF</a>
          </FalconCardHeader>

          <CardBody>
            {
              country == 0 ? (<DataDictionaryComponent countryCode="ec" />) : (<DataDictionaryComponent countryCode="pe" />)}
          </CardBody>
        </Card>
        <Card>
          <FalconCardHeader title="Respuesta JSON" >
          </FalconCardHeader>
          <Media body className="ml-3">
            <CodeHighlight
              code={
                `{
  "personId": "100XXXXXXX",
  "accountId": "16d57e58-8d12-4266-b944-48cc15c9cccf",
  "accountType": "FINANCIAL",
  "queryId": "97b408ac-96da-4bda-bfd0-55443941378a",
  "countryCode": "ec",
  "dateCreated": "2021-03-16T16:59:12.434Z",
  "dateToAnalyze": "2021-03-16T16:58:43.870Z",
  "schemaVersion": "1",
  "basicIndex": {
    "altData": {},
    "digitalFootprintData": {},
    "geoData": {},
    "publicData": {}
  },
  "rawData": {
      "altData": {},
      "digitalFootprintData": {
          "googleSearch_count": 1,
          "googleSearch_pdfCount": 1,
          "googleSearch_pdfPct": 1,
          "googleSearch_newsCount": 0,
          "googleSearch_newsPct": 0,
          "emailBreach_count": 1,
          "emailBreach_count01Y": 1,
          "emailBreach_count05Y": 1,
          "emailBreach_creditCardsCount": 0,
          "emailBreach_diasDesdeUltimo": 5,
          "emailBreach_diasDesdePrimero": 5
      },
      "geoData": {
          "political_level1_name": "PICHINCHA",
          "political_level2_name": "QUITO",
          "political_level3_name": "QUITO",
          "applicant_location_type": "HOME",
          "applicant_lat": -0.262696,
          "applicant_lng": -78.544428,
          "restaurants_count500m": 20,
          "restaurants_count750m": 20,
          "restaurants_count1000m": 20,
          "restaurants_distAvg500m": 183.80054094556078,
          "restaurants_distAvg750m": 183.80054094556078,
          "restaurants_distAvg1000m": 183.80054094556078,
          "banks_count500m": 2,
          "banks_count750m": 4,
          "banks_count1000m": 8,
          "banks_distAvg500m": 458.3531689637092,
          "banks_distAvg750m": 551.7572245561176,
          "banks_distAvg1000m": 726.8648543553338,
          "atms_count500m": 4,
          "atms_count750m": 11,
          "atms_count1000m": 20,
          "atms_distAvg500m": 346.3334262221989,
          "atms_distAvg750m": 554.5511790981362,
          "atms_distAvg1000m": 699.7474743788687,
          "education_count500m": 4,
          "education_count750m": 13,
          "education_count1000m": 20,
          "education_distAvg500m": 358.7162415688809,
          "education_distAvg750m": 547.3206554339141,
          "education_distAvg1000m": 656.3602609671449,
          "health_count500m": 10,
          "health_count750m": 32,
          "health_count1000m": 48,
          "health_distAvg500m": 378.5609638584412,
          "health_distAvg750m": 548.4271953506764,
          "health_distAvg1000m": 663.6891787925877,
          "satImage_z16": "https://storage.googleapis.com/altscore-satelital-images/img_4daabd36-010b-413d-8b0f-e0ae67d2777c.png",
          "satImage_z18": "https://storage.googleapis.com/altscore-satelital-images/img_22ef6fc9-a8d1-45c1-887a-3ac1f0ab3e40.png",
          "surroundingBuildings_heightAvg500m": 3,
          "surroundingBuildings_count500m": 48,
          "surroundingRoads_primaryCount500m": 2,
          "surroundingRoads_tertiaryCount500m": 3,
          "surroundingRoads_pavedCount500m": 13,
          "surroundingRoads_unpavedCount500m": 0,
          "surroundingPoi_artsAndEntertainmentCount500m": 0,
          "surroundingPoi_commercialServicesCount500m": 1,
          "surroundingPoi_foodAndDrinkStoresCount500m": 2,
          "surroundingPoi_historicCount500m": 0,
          "surroundingPoi_industrialCount500m": 0,
          "surroundingPoi_landmarkCount500m": 0,
          "surroundingPoi_lodgingCount500m": 1,
          "surroundingPoi_religionCount500m": 3,
          "surroundingPoi_sportAndLeisureCount500m": 0,
          "surroundingLandUse_woodsCount500m": 1,
          "surroundingLandUse_parksCount500m": 11,
          "surroundingLandUse_sandCount500m": 0,
          "surroundingLandUse_airportCount500m": 0,
          "surroundingLandUse_agricultureCount500m": 0,
          "surroundingNaturalFeatures_totalCount500m": 1
      },
      "publicData": {
          "educacionSecundaria_tituloRegCount": -999999,
          "educacionSecundaria_primerTituloReg_titulo": "-999999",
          "educacionSecundaria_primerTituloReg_especialidad": "-999999",
          "educacionSecundaria_primerTituloReg_provincia": "-999999",
          "educacionSecundaria_primerTituloReg_institucionPuntajeSerBachiller": -999999,
          "educacionSecundaria_primerTituloReg_tipoInstitucion": "-999999",
          "educacionSecundaria_primerTituloReg_institucionRanking": -999999,
          "educacionSuperior_tituloRegCount": 1,
          "educacionSuperior_tituloRegExtranjeroCount": 0,
          "educacionSuperior_tituloRegMasterCount": 0,
          "educacionSuperior_tituloRegDoctoradoCount": 0,
          "educacionSuperior_gradoTituloRegCount": 1,
          "educacionSuperior_gradoTituloRegExtranjeroCount": 0,
          "educacionSuperior_gradoDiasDesdeRegistroMax": 1765,
          "educacionSuperior_posgradoTituloRegCount": 0,
          "educacionSuperior_posgradoTituloRegExtranjeroCount": 0,
          "educacionSuperior_posgradoDiasDesdeRegistroMax": -999997,
          "educacionSuperior_gradoPrimerTituloReg_titulo": "TECNICO EN SEGURIDAD CIUDADANA Y ORDEN PUBLICO",
          "educacionSuperior_posgradoPrimerTituloReg_titulo": "-999997",
          "licencia_citaciones_pagadasCount": 2,
          "licencia_citaciones_pagadasCount06M": 0,
          "licencia_citaciones_pagadasCount12M": 0,
          "licencia_citaciones_pagadasAmount": 190.2,
          "licencia_citaciones_pagadasAmount06M": 0,
          "licencia_citaciones_pagadasAmount12M": 0,
          "licencia_citaciones_pendientesCount": 1,
          "licencia_citaciones_pendientesCount06M": 0,
          "licencia_citaciones_pendientesCount12M": 1,
          "licencia_citaciones_pendientesAmount": 167.84,
          "licencia_citaciones_pendientesAmount06M": 0,
          "licencia_citaciones_pendientesAmount12M": 167.84,
          "licencia_citaciones_pendientesDiasDesdeRegistroMax": 433,
          "licencia_citaciones_totalCount": 3,
          "licencia_citaciones_totalCount06M": 0,
          "licencia_citaciones_totalCount12M": 1,
          "licencia_citaciones_diasDesdeCitacionMasReciente": 241,
          "electricidadCentroSur_contratosCount": 0,
          "electricidadCentroSur_consumoAvg03M": -999997,
          "electricidadCentroSurNoParam_planillasPendientesCount": -999997,
          "electricidadCentroSurNoParam_deudaSum": -999997,
          "electricidadCNEL_contratosCount": 0,
          "electricidadCNEL_consumoAvg03M": -999997,
          "electricidadCNELNoParam_planillasPendientesCount": -999997,
          "electricidadCNELNoParam_deudaSum": -999997,
          "licencia_historialPuntos_saldoMin": 30,
          "licencia_historialPuntos_perdidosSum06M": 0,
          "licencia_historialPuntos_perdidosSum12M": 0,
          "licencia_historialPuntos_eventosNegCount06M": 0,
          "licencia_historialPuntos_eventosNegCount12M": 0,
          "licencia_historialPuntos_perdidosAvg": 0,
          "licencia_historialPuntos_puntos": 30,
          "licenciaNoParam_licenciaNoProfesionalCount": 2,
          "licenciaNoParam_licenciaProfesionalCount": 0,
          "vehiculosAsociados_count": 1,
          "vehiculosAsociados_vehiculoAntiguoColor": "NEGRO",
          "vehiculosAsociados_vehiculoAntiguoClase": "AUTOMOVIL",
          "vehiculosAsociados_vehiculoAntiguoAnio": 2019,
          "vehiculosAsociados_vehiculoNuevoColor": "NEGRO",
          "vehiculosAsociados_vehiculoNuevoClase": "AUTOMOVIL",
          "vehiculosAsociados_vehiculoNuevoAnio": 2019
      }
  },
  "score": {
      "finance": {
          "altData": {
              "altScore_v1": 353,
              "altScoreRiskGroup_v1": "C"
          },
          "digitalFootprintData": {
              "subScore_v1": 300
          },
          "geoData": {
              "subScore_v1": 337
          },
          "publicData": {
              "subScoreTransporte_v1": 427,
              "subScoreEducacion_v1": 362,
              "subScoreElectricidadCNEL_v1": -999999,
              "subScore_v1": 491
          }
      }
  },
  "isSuccess": true
}`}
              language="bash"
            />
          </Media>
        </Card>
        <Card className="mt-3">
          <FalconCardHeader title="End Point de prueba" >
          </FalconCardHeader>
          <Media className="m-3 ">
            <CodeHighlight
              style={{ width: '300px' }}
              code={
                window.location.href == 'http://localhost:3000/docs' ?
                  `POST 'https://localhost:4000/v1/test'` : window.location.href == 'https://staging.dashboard.altscore.ai/docs' ?
                    `POST 'https://staging.api.altscore.ai/v1/test'` :
                    `POST 'https://api.altscore.ai/v1/test'`
              }
              language="bash"
            />
            <ButtonIcon
              color="transparent"
              //className="ml-auto px-5"
              icon='copy'
              iconAlign=""
              onClick={() => copyCodeToClipboard()}
            >

            </ButtonIcon>

          </Media>
        </Card>

        <Card className="mt-3">
          <FalconCardHeader title="Open API" >
          </FalconCardHeader>
          <Media className="m-3 ">
            <CodeHighlight
              style={{ width: '300px' }}
              code={window.location.href == 'http://localhost:3000/docs' ?
                `Importar desde postman 'http://localhost:4000/swagger-export'` : window.location.href == 'https://staging.dashboard.altscore.ai/docs' ?
                  `Importar desde postman 'https://staging.api.altscore.ai/swagger-export'` :
                  `Importar desde postman 'https://api.altscore.ai/swagger-export'`}
              language="bash"
            />
            <ButtonIcon
              color="transparent"
              //className="ml-auto px-5"
              icon='copy'
              iconAlign=""
              onClick={() => copyLinkToClipboard()}
            >

            </ButtonIcon>
          </Media>
          <Media className="m-3 ">
            <a
              style={{ color: '#ff79c6' }}
              target="_blank"
              href={window.location.href == 'http://localhost:3000/docs' ?
                'http://localhost:4000/api-docs' : window.location.href == 'https://staging.dashboard.altscore.ai/docs' ?
                  'https://staging.api.altscore.ai/api-docs' :
                  'https://api.altscore.ai/api-docs'}>
              Ir a Open Api
            </a>&nbsp; o&nbsp; <a
              style={{ color: '#ff79c6' }}
              href=
              {window.location.href == 'http://localhost:3000/docs' ?
                'http://localhost:4000/swagger-export' : window.location.href == 'https://staging.dashboard.altscore.ai/docs' ?
                  'https://staging.api.altscore.ai/swagger-export' :
                  'https://api.altscore.ai/swagger-export'}>
              Descargar colección de postman
            </a>
            {/* <ButtonIcon
                     color="transparent"
                     //className="ml-auto px-5"
                     icon='copy'
                     iconAlign=""
                      onClick={() => copyLinkToClipboard()}
                    >

                    </ButtonIcon> */}
          </Media>
        </Card>
      </div>
    </Fragment>
  );
};
export default APIReference;
