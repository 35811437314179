import React, { Fragment, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import Col from 'reactstrap/es/Col';
import CustomInput from 'reactstrap/es/CustomInput';
import Media from 'reactstrap/es/Media';
import Row from 'reactstrap/es/Row';
import Alert from 'reactstrap/lib/Alert';
import CodeHighlight from '../components/common/CodeHighlight';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Flex from '../components/common/Flex';
import CardDropdown from '../components/dashboard-alt/CardDropdown';
import PageHeader from './../components/common/PageHeader';
import DataDictionaryComponent from './DataDictionaryComponent';
import { isIterableArray } from '../helpers/utils';
import sdkImplementationCode from './data/sdk_implementation_code_text';

const SdkImplementationPage = () => {
    const [programmingLanguageIndex, setProgrammingLanguageIndex] = useState(0);
    //const [country, setCoutry] = useState(0);
    return (
      <Fragment>
        <div>
          <PageHeader title="Definición" className="mb-3 ">
            <p className="mt-2 mb-0">
              <code>Altscore SDK</code> consta de un módulo de <code>Flutter</code>. El cual se encarga de recolectar información del dispositivo y del comportamiento de la persona frente a la aplicación.
            </p>
            <p>
            El módulo de flutter se integra facilmente con sus aplicaciones <code>nativas de Android</code> y se muestra en forma de <code>Modal</code> 
            </p>
          </PageHeader>
          <Card>
            <FalconCardHeader title="Configuración del proyecto" />
            <Row>
              <Col xs="12">
                <CardBody>
                  <p>
                      <code>Altscore SDK </code> requiere que se realicen pequeñas configuraciones en su proyecto de <code>Android</code> para ser implementado
                  </p>
                </CardBody>
                <Card>
                  <CardBody>
                    <Alert color="info" className="px-0 pt-0 bg-white">
                      <FalconCardHeader className="ml-0 mt-0" title="1. Definir Permisos">
                      </FalconCardHeader>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
    Agregue las siguientes lineas de permisos en el archivo <code>AndroidManifest.xml</code> de su proyecto de <code>Android</code> antes de la etiqueta <code>{'<application>'}</code>
                          </p>
                          <CodeHighlight
                              code={`<uses-permission android:name="android.permission.INTERNET"/>
<uses-permission android:name="android.permission.READ_CONTACTS"/> 
<uses-permission android:name="android.permission.WRITE_CONTACTS"/>
<uses-permission android:name="android.permission.READ_CALENDAR"/>
<uses-permission android:name="android.permission.WRITE_CALENDAR"/>
<uses-permission android:name="android.permission.READ_EXTERNAL_STORAGE"/>`}
                              language="xml"
                            />
                        </Media>
                      </Media>
                    </Alert>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Alert color="info" className="px-0 pt-0 bg-white">
                      <FalconCardHeader className="ml-0 mt-0" title="2. Definir Flutter Activity">
                      </FalconCardHeader>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
    incluya la flutter activity en el archivo <code>AndroidManifest.xml</code> de su proyecto de <code>Android</code> dentro de la etiqueta <code>{'<application>'}</code>
                          </p>
                          <CodeHighlight
                              code={`<activity
android:name="io.flutter.embedding.android.FlutterActivity"
android:theme="@style/AppTheme"
android:configChanges="orientation|keyboardHidden|keyboard|screenSize|locale|layoutDirection|fontScale|screenLayout|density|uiMode"
android:hardwareAccelerated="true"
android:windowSoftInputMode="adjustResize"/>`}
                              language="xml"
                            />
                        </Media>
                      </Media>
                    </Alert>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Alert color="info" className="px-0 pt-0 bg-white">
                      <FalconCardHeader className="ml-0 mt-0" title="3. Configuración de gradle">
                      </FalconCardHeader>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
    agregue los siguientes repositorios en su archivo<code> build.gradle</code> a nivel de app en su proyecto de <code>Android</code> dentro de <code>{'repositories'}</code> de la siguiente forma:
                          </p>
                          <CodeHighlight
                              code={`repositories {
...
maven {
    url 'http://nexus.altscore.repo/repository/maven-releases/'
}
maven {
    url 'https://storage.googleapis.com/download.flutter.io'
}
...
}`}

                              language="xml"
                            />
                        </Media>
                        <br></br>
                      </Media>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
     también se debe incluir la siguiente dependencia en el mismo archivo
                          </p>
                          <CodeHighlight
                              code={`dependencies {
....
implementation 'com.kinlending.altscore_module:altscore_sdk:1.0'
....
}
                            `}

                              language="xml"
                            />
                        </Media>
                        
                      </Media>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
El motor de Flutter para Android utiliza funciones de Java 8, asegurese de declarar la compatibilidad dentro del bloque <code>android{}</code>
                          </p>
                          <CodeHighlight
                              code={`android {
//...
compileOptions {
sourceCompatibility 1.8
targetCompatibility 1.8
  }
}`}

                              language="xml"
                            />
                        </Media>
                      </Media>
                    </Alert>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
          <Card className="mt-3">

            <FalconCardHeader title="Implementación" >
              <Flex>
              <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        bsSize="sm"
                        className="select-month mr-2"
                        value={programmingLanguageIndex}
                        onChange={({ target }) => setProgrammingLanguageIndex(target.value)}
                      >
                       <option key={0} value={0}>Java</option>
                       <option key={1} value={1}>Kotlin</option>
                      </CustomInput>
              </Flex>
            </FalconCardHeader>
            <Row>
              <Col xs="12">
                <CardBody>
                  <p>
                      Ahora que su proyecto cuenta con todas las configuraciones necesarias es momento de implementar <code>Altscore SDK </code>
                  </p>
                </CardBody>
                <Card>
                  <CardBody>
                    <Alert color="info" className="px-0 pt-0 bg-white">
                      <FalconCardHeader className="ml-0 mt-0" title="1. Precalentar motor de flutter">
                      </FalconCardHeader>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
    Con el objetivo de que la ejecución de <code>Altscore SDK</code> en su aplicación sea lo mas rapido posible sin tiempos de espera se recomienda instanciar el motor de flutter en su <code> MainActivity</code> y usarla posteriormente en cualquier parte de su aplicación
                          </p>
                          <h6 className="mt-3">
                          Si usted usa{' '}
                          <strong>
                            <code>{sdkImplementationCode[programmingLanguageIndex].name}</code>
                          </strong>{' '}
                          , ejecute:
                        </h6>
                          <CodeHighlight
                              code={sdkImplementationCode[programmingLanguageIndex].warm_engine_flutter_code_lines}
                              language="java"
                            />
                        </Media>
                      </Media>
                    </Alert>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Alert color="info" className="px-0 pt-0 bg-white">
                      <FalconCardHeader className="ml-0 mt-0" title="2. Iniciar Altscore SDK">
                      </FalconCardHeader>
                      <Media>
                        <Media body className="ml-3 mr-3">
                          <p>
                              Para iniciar <code>Altscore SDK</code> es necesario llamar al motor de flutter ya definido en un intent y enviar la información necesaria mediante MethodChannel
                          </p>
                          <h6 className="mt-3">
                          Si usted usa{' '}
                          <strong>
                            <code>{sdkImplementationCode[programmingLanguageIndex].name}</code>
                          </strong>{' '}
                          , ejecute:
                        </h6>
                          <CodeHighlight
                           code={sdkImplementationCode[programmingLanguageIndex].run_sdk_code_lines}
                              language="java"
                            />
                        </Media>
                      </Media>
                    </Alert>
                  </CardBody>
                </Card>
                </Col>
            </Row>
          </Card>
           </div>
      </Fragment>
    );
  };
  export default SdkImplementationPage;
  