import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card,
    CardBody,
    Col,
    Row,
    Collapse,
    Table,
    Button
} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import useFakeFetch from '../hooks/useFakeFetch';
import { isIterableArray } from '../helpers/utils';
import rawDictionaryEcuador from './data/dictionary_data.json'
import rawDictionaryPeru from './data/dictionary_data_peru.json'
import { accountService } from '../services';

const CustomerDetailRow = ({ title, isLastItem, children }) => (
    <Row>
        <Col xs={5} sm={4}>
            <p
                className={classNames('font-weight-semi-bold', {
                    'mb-0': isLastItem,
                    'mb-1': !isLastItem
                })}
            >
                {title}
            </p>
        </Col>
        <Col>{children}</Col>
    </Row>
);

CustomerDetailRow.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isLastItem: PropTypes.bool
};

CustomerDetailRow.defaultProps = { last: false };

const posibleValuesTable = (indexDetails) => {
    return indexDetails.map((detail, index) => {
        return <tr key={index}>
            <td>{detail.value}</td>
            <td>{detail.description}</td>
        </tr>
    })
}
const IndexContainer = ({ indexWord }) => {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <Card className={!collapsed?"mb-1 ml-3 mr-3 border" :"mb-1 ml-1 mr-1" }>
        
          <div>
          <Button color="link"  to="/pages/events" onClick={() => setCollapsed(!collapsed)}>
                    {indexWord.indexName} 
                    <FontAwesomeIcon icon="chevron-up" className="ml-1 fs--2" transform={collapsed ? 'rotate-180' : ''} />
                    
                </Button>
                {indexWord.type!="Object"?<span className="badge badge-soft-success badge-pill">
                            {indexWord.type}
                                </span>:<div></div>}
          </div>
            <Collapse isOpen={!collapsed} >
                <CardBody className={!collapsed?"bg-dark border-left border-right border-bottom":"bg-dark"}>
                    <Row>
                        <Col  xs={indexWord.error_values != null && indexWord.error_values.length == 0?"12":"5"}>
                            {indexWord.type!="Object"?
                                <CustomerDetailRow title="Tipo">
                            <span className="badge badge-soft-success badge-pill">
                            {indexWord.type}
                                </span>
                               </CustomerDetailRow>:<div></div>
                            }
                            {
                                 indexWord.description!=""?
                               <CustomerDetailRow title="Descripción" >
                               
                                {indexWord.description ? indexWord.description : <p className="font-italic text-400 mb-1">Sin descripción</p>}
                            </CustomerDetailRow> :<div></div>
                            }
                            
                        </Col>
                        {
                            indexWord.error_values != null && indexWord.error_values.length > 0 ? 
                            <Col xs="7" className="">
                                <Card>
                                <FalconCardHeader title="Errores posibles" />
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Valor</th>
                                                <th>Descripcion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {posibleValuesTable(indexWord.error_values)}
                                        </tbody>
                                    </Table></Card>
                            </Col> : <div />
                        }
                    </Row>
                    <div className="mt-1"></div>
                    {indexWord.childs!=null&& isIterableArray(indexWord.childs) ?indexWord.childs.map((child, index) => <IndexContainer indexWord={child} key={child.indexName} />):<div></div>}
                </CardBody>
            </Collapse>
        </Card>
    );
};

const DictionaryView = ({ countryCode }) => {
    const [dictionary, setDictionary] = useState([]);
    useEffect(() => {
      const getDataDictionary = async () => {
        try {
          const res = await accountService.get('/accounts/getDataDictionary?countryCode=ec');
          console.log(res);
          setDictionary(res.data);
        } catch (err) {
          alert(err);
        }
      };
  
      getDataDictionary();
    }, []);
    const { loading, data: indexWords } = useFakeFetch(countryCode=="ec"?rawDictionaryEcuador:rawDictionaryPeru);
    return (<Fragment>
        <div className="mt-3" >
             {isIterableArray(dictionary) && dictionary.map((word, index) => <IndexContainer indexWord={word} key={word.indexName} />)}
        </div>
    </Fragment>);

}



export default DictionaryView;
