export default
  [
    {
      name: "Python",
      autentication_code_text:
`import requests
url = "https://api.altscore.ai/v1/"
payload = {}
headers = {
  'Authorization': 'Bearer [API_KEY]'
}
response = requests.post(url, headers=headers, json=payload)
print(response.json())`,
      implementation_code_text: 
`import requests
url = "https://api.altscore.ai/v1"
payload = {    
  "personId": "1000000000",    
  "countryCode": "ec",    
  "name": "NOMBRE DEL CLIENTE",    
  "address": {        
    "country": "Ecuador", 
    "city": "Quito",        
    "street1": "Dr Raul Montalvo ",        
    "street2": "Arturo Hidalgo Rivedeneira",        
    "type": "HOME"    
  },     
  "email": "client@altscore.ai"
}
headers = {
    'Authorization': 'Bearer [API_KEY]'
}
response = requests.post(url, headers=headers, json=payload)
print(response.json())`
    },
    {
      name: "C#",
      autentication_code_text: 
`var client = new RestClient("https://api.altscore.ai/v1/");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("Authorization", "Bearer [API_KEY]");
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);`,
      implementation_code_text: 
`var client = new RestClient("https://api.altscore.ai/v1");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("Authorization", "Bearer [API_KEY]");
request.AddParameter("application/json", "{   
  \"personId\": \"10000000000\",   
  \"countryCode\": \"ec\",   
  \"name\": \"NOMBRE DE CLIENTE\",   
  \"address\": {       
    \"country\": \"Ecuador\",   
    \"city\": \"Quito"\,    
    \"street1\": \"Dr Raul Montalvo \",       
    \"street2\": \"Arturo Hidalgo Rivedeneira\",       
    \"type\": \"HOME\"   
  },   
  \"email\": \"mparrat63@hotmail.com\"
}",  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);`
    },
    {
      name: "Javascript",
      autentication_code_text: 
`var axios = require('axios');
var config = {
  method: 'POST',
  url: 'https://api.altscore.ai/v1/',
  headers: { 
    'Authorization': 'Bearer [API_KEY]'
  },
};
axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
}).catch(function (error) {
  console.log(error);
});`,
      implementation_code_text: 
`var axios = require('axios');
var data = JSON.stringify({
  "personId": "10000000000",
  "countryCode": "ec",
  "name": "NOMBRE DEL CLIENTE",
  "address": {
    "country": "Ecuador",
    "city": "Quito",
    "street1": "Dr Raul Montalvo ",
    "street2": "Arturo Hidalgo Rivedeneira",
    "type": "HOME"
  },
  "email": "client@altscore.ai"
});
var config = {
  method: 'POST',
  url: 'https://api.altscore.ai/v1/',
  headers: {
    'Authorization': 'Bearer [API_KEY]'
  },
  data: data
};

axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  }).catch(function (error) {
    console.log(error);
  });`
    }
  ]
