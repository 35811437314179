import React, { useContext } from "react";
import Loader from './components/common/Loader';



export const LoadingWhole = () => {
  return (
    <div>
      <Loader/>
    </div>
  );
};
