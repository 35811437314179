const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";
const isStaging = process.env.REACT_APP_ENVIRONMENT === "staging";

const devApiConfig = {
    baseUrl: "http://localhost:4000",
};

const stagingApiConfig = {
    baseUrl: "https://staging.api.altscore.ai",
};

const prodApiConfig = {
    baseUrl: "https://api.altscore.ai",
};

const apiConfig = isStaging ? stagingApiConfig : isProduction ? prodApiConfig : devApiConfig;

export { apiConfig };
