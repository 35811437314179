import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from './context/Auth';
import { LoadingWhole } from './LoadingWhole';


const PublicRoute = ({ component: RouteComponent, ...rest }) => {
  const {currentUser, currentAccount, isLoadingAuth} = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={routeProps =>
        (!currentUser || !currentAccount) && !isLoadingAuth ? (
          <RouteComponent {...routeProps} />
        ) : !isLoadingAuth ? (
          <Redirect to={'/'} />
        ) : <LoadingWhole/>
      }
    />
  );
};


export default PublicRoute
