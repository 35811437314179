import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Card, CardBody, CustomInput } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { rgbaColor, themeColors } from '../../helpers/utils';
import AppContext from '../../context/Context';
import { accountService } from '../../services';
import FormGroupSelect from '../common/FormGroupSelect';
import Label from 'reactstrap/es/Label';
import Datetime from 'react-datetime';
import FormGroupInput from '../common/FormGroupInput';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import TableApiCalls from './TableApiCalls';

const periods = [
 /*  { id: 0, label: 'Last 24 hours', days: 1 }, */
  { id: 1, label: 'Last week', days: 7 },
  { id: 2, label: 'Last month', days: 30 },
  { id: 3, label: 'Custom' }
];
const UsageLineChart = () => {
  const { isDark } = useContext(AppContext);
  const [usage, setUsage] = useState({
    byDate: [],
    totalCount: 0,
    successCount: 0,
    errorCount: 0
  });
  const [selectedPeriod, setSelectedPeriod] = useState(1);

  const [periodDates, setPeriodDates] = useState({
    startDate: moment().subtract(7, 'd'),
    endDate: moment().add()
  });

  const [focusedInput, setFocusedInput] = useState(null);

  function onDateChange(dates) {
    if (dates.startDate && dates.endDate) {
      setPeriodDates({
        startDate: dates.startDate,
        endDate: dates.endDate
      });
    }
  }

  function onFocusChange(focusedInput) {
    setFocusedInput(focusedInput);
  }

  const getUsage = async () => {
    try {
      const params = {
        startDate: periodDates.startDate.toDate(),
        endDate: periodDates.endDate.toDate()
      };
      const res = await accountService.get('/accounts/usage', { params });
      setUsage(res.data);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getUsage();
  }, [periodDates]);

  useEffect(() => {
    const period = periods.find(period => period.id === Number(selectedPeriod));

    console.log(selectedPeriod);
    if (Number(selectedPeriod) === 3) {
      console.log('end!!!');
      return;
    } else {
      setPeriodDates({
        startDate: moment().subtract(period.days, 'd'),
        endDate: moment()
      });
    }
  }, [selectedPeriod]);

  useEffect(() => {
    getUsage();
  }, []);

  const config = {
    data(canvas) {
      const ctx = canvas.getContext('2d');
      const gradientFill = isDark
        ? ctx.createLinearGradient(0, 0, 0, ctx.canvas.height)
        : ctx.createLinearGradient(0, 0, 0, 250);
      gradientFill.addColorStop(0, isDark ? 'rgba(44,123,229, 0.5)' : 'rgba(255, 255, 255, 0.3)');
      gradientFill.addColorStop(1, isDark ? 'transparent' : 'rgba(255, 255, 255, 0)');

      return {
        labels: usage.byDate.map(dateUsage => dateUsage.date),
        datasets: [
          {
            label: 'Success',
            borderWidth: 2,
            data: usage.byDate.map(dateUsage => dateUsage.successCount),
            borderColor: rgbaColor(themeColors.success, 0.8),
            backgroundColor: gradientFill
          },
          {
            label: 'Errors',
            borderWidth: 2,
            data: usage.byDate.map(dateUsage => dateUsage.errorCount),
            borderColor: rgbaColor(themeColors.danger, 0.8),
            backgroundColor: gradientFill
          }
        ]
      };
    },
    options: {
      legend: { display: false },
      tooltips: {
        mode: 'x-axis',
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: tooltipItem => `${tooltipItem.datasetIndex === 0 ? 'Success: ' : 'Error: '} ${tooltipItem.yLabel}`,
          title: () => `Usage`
        }
      },
      hover: { mode: 'label' },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              color: rgbaColor('#fff', 1)
            }
          }
        ]
      }
    }
  };

  return (
    <div>
      <Card className="mb-3">
        <CardBody className="rounded-soft bg-gradient">
          <Row className="text-white align-items-center no-gutters">
            {/*<h4 className="text-white mb-0">Usage</h4>*/}

            <Col>
              <Row>
                <Col>
                  <p className="text-white mb-0">Success Calls: {usage.successCount}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-white mb-0">Error Calls: {usage.errorCount}</p>
                </Col>
              </Row>
            </Col>
            <Col xs="auto" className="d-none d-sm-block">
              <FormGroupSelect
                loading={false}
                type={'select'}
                id="period"
                label="Period"
                options={periods.map(period => {
                  return { value: period.id, label: period.label };
                })}
                value={selectedPeriod}
                onChange={({ target }) => setSelectedPeriod(target.value)}
              />
            </Col>
            <Col xs="auto" className="d-none d-sm-block mt-3">
              {Number(selectedPeriod) === 3 ? (
                <DateRangePicker
                  style={{ marginLeft: '-35px' }}
                  className="custom-datepicker-dark"
                  startDate={periodDates.startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={periodDates.endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={onDateChange}
                  focusedInput={focusedInput}
                  onFocusChange={onFocusChange}
                  isOutsideRange={day => moment().diff(day) < 0}
                />
              ) : null}
            </Col>

            {/*<Col xs="auto" className="d-none d-sm-block">*/}
            {/*  <p className="text-white mb-0">Success Calls: {usage.successCount}</p>*/}
            {/*</Col>*/}
            {/*<Col xs="auto" className="d-none d-sm-block">*/}
            {/*  <p className="text-white mb-0">Error Calls: {usage.errorCount}</p>*/}
            {/*</Col>*/}
          </Row>

          {/*</Row>*/}
          <Line data={config.data} options={config.options} width={1618} height={375} />
        </CardBody>
      </Card>
      <TableApiCalls listByDateCalls={usage.byDate} />
    </div>
  );
};

export default UsageLineChart;
