import axios from 'axios';
import firebaseApp from '../base';
import { apiConfig } from '../config/api';

export class BaseAPI {
  baseUrl;
  axiosInstance;
  constructor() {
    this.baseUrl = apiConfig.baseUrl;
    this.axiosInstance = axios.create({});
  }

 async get(url, options) {
    return this.httpCall(url, "GET", options)
  }

  async post(url, options) {
    return this.httpCall(url, "POST", options)
  }

  async httpCall(url, method, options) {
    console.log(firebaseApp.auth().currentUser);
    const idToken = firebaseApp.auth().currentUser ?  await firebaseApp.auth().currentUser.getIdToken() : null;
    const headers = {
      Authorization: `Bearer ${idToken}`
    };
    return this.axiosInstance({
      method: method,
      url: `${this.baseUrl}${url}`,
      data: options && options.data ? options.data : null,
      params: options && options.params ? options.params : null,
      headers: headers,
      // withCredentials: options && options.withCredentials ? options.withCredentials : false,
      // httpsAgent: options && options.httpsAgent ? options.httpsAgent : null,
    });
  }
}
