import React, { useEffect, useState, createContext } from 'react';
import firebaseApp from '../base';
import { accountService } from '../services';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [currentUser, setCurrentUser] = useState('init');
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    // setCurrentUser(firebaseApp.auth().currentUser);
  }, []);

  useEffect(() => {
    console.log(currentUser);
    if (currentUser !== 'init') {
      if (currentUser) {
        getAccount();
      } else {
        setIsLoadingAuth(false);
      }
    }
  }, [currentUser]);
  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(setCurrentUser);
    // firebaseApp.auth().onAuthStateChanged(async user => {
    //   if (user) {
    //     try {
    //       console.log(user)
    //       setCurrentUser(user);
    //       const res = await accountService.get('/accounts/info');
    //       const account = res.data;
    //       setCurrentAccount(account);
    //     } catch (err) {
    //       console.log(err);
    //       setCurrentUser(null);
    //       setCurrentAccount(null);
    //     }
    //   }
    // });
  }, []);

  const getAccount = async () => {
    console.log(currentUser);

    try {
      setIsLoadingAuth(true);
      const res = await accountService.get('/accounts/info');

      const account = res.data;
      setCurrentAccount(account);
      setIsLoadingAuth(false);
    } catch (e) {
      console.log(e);
      setIsLoadingAuth(false);
    }
  };

  return <AuthContext.Provider value={{ currentUser, currentAccount, isLoadingAuth }}>{children}</AuthContext.Provider>;
};
